import { Dispatch } from '../contracts/StateFunctions'
import { Logger } from '../composition/Logger'

export const state = () => ({})

export const mutations = {}

export const actions = {
  async nuxtServerInit({ dispatch }: Dispatch, { app, req, error }) {
    try {
      await Promise.all([
        dispatch('settings/getSettings', { ...app, error, req }),
      ])
    } catch (error) {
      Logger.error(error)
    }
  },
}
