<template>
  <section class="section">
    <slot />
  </section>
</template>

<script>
export default {
  name: 'Section',
}
</script>

<style lang="scss" scoped>
$mobile: $MOBILE;
$mobile_tablet: $MOBILE_TABLET;
$tablet: $TABLET;
$tablet_desktop: $TABLET_DESKTOP;
$desktop: $DESKTOP;
$desktop_large: $DESKTOP_LARGE;
$containerSideOffsetDesktop: $CONTAINER_SIDE_OFFSET_DESKTOP;
$containerSideOffsetTablet: $CONTAINER_SIDE_OFFSET_TABLET;
$containerSideOffsetMobile: $CONTAINER_SIDE_OFFSET_MOBILE;

$verticalOffsetMobile: $VERTICAL_OFFSET_MOBILE;
$verticalOffsetTablet: $VERTICAL_OFFSET_TABLET;
$verticalOffsetDesktopTop: $VERTICAL_OFFSET_DESKTOP_TOP;
$verticalOffsetDesktopBottom: $VERTICAL_OFFSET_DESKTOP_BOTTOM;

.section {
  overflow: hidden;
  position: relative;
  &--content {
    padding: 10rem 0;

    @media #{$tablet} {
      padding: 6rem 0;
    }

    @media #{$mobile} {
      padding: 4rem 0;
    }
  }
  &-basic.border-top {
    position: relative;
    &::after {
      content: '';
      display: block;
      position: absolute;
      background-color: $BORDER_PRIMARY;
      height: 1px;
      width: 130.8rem;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      @include mobile {
        content: none;
      }
    }
  }
  .section-form + &-basic {
    &::after {
      background-color: transparent;
    }
  }
}

.is-debug .section {
  box-shadow: inset 0 0 0 1px rgba(0, 255, 255, 0.5);
}

@media #{$mobile} {
  .section {
    padding: $verticalOffsetMobile $containerSideOffsetMobile;
  }
}

@media #{$tablet} {
  .section {
    padding: $verticalOffsetTablet $containerSideOffsetTablet;
  }
}

@media #{$desktop} {
  .section {
    padding: $verticalOffsetDesktopTop 0 $verticalOffsetDesktopBottom 0;
  }
}
</style>
