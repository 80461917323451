// @ts-nocheck
import { buttonsAdapter } from "./buttonsAdapter";

export const sectionDataAdapter = (data: any) => {
  const {
    sectionMainTitle,
    sectionSideTitle,
    sectionId,
    sectionDescription,
    sectionDate,
    sectionButtonsAlignment,
    sectionButtons,
    linkText,
    linkUrl,
    sectionBackground,
  } = data

  return {
    sectionMainTitle,
    sectionSideTitle,
    sectionId,
    sectionDescription,
    sectionDate,
    sectionButtonsAlignment,
    sectionBackground,
    sectionButtons: buttonsAdapter(sectionButtons),
    sectionLink: linkText
      ? {
        value: linkText,
        link: linkUrl || '#',
        arrowType: 'arrow-right',
      }
    : null,
  }
}
