import gql from 'graphql-tag'

export const search = gql`
  query searchQuery(
    $searchString: String
    $searchCategory: String
    $first: Int
    $after: ElementWhereUniqueInput
    $orderBy: [ElementOrderByInput!]
  ) {
    elements(
      where: {
        iblock: { code: { equals: $searchCategory } },
        propertyValues: { none: { value: { contains: "noindex, nofollow"} } }
        AND: [
          {
            propertyValuesEnum: {
              none: { property: { OR: [{ code: { equals: "hiddenPage" } }, { code: { equals: "uniquePages" } } ] } }
            }
          }
          {
            OR: [
              {
                AND: [
                  { name: { contains: $searchString } }
                  { iblock: { code: { equals: $searchCategory } } }
                ]
              }
              {
                AND: [
                  {
                    propertyValues: {
                      some: { value: { contains: $searchString } }
                    }
                  }
                  {
                    iblock: {
                      OR: [
                        { code: { equals: "document" } }
                        { code: { equals: "rgs_news" } }
                      ]
                    }
                  }
                ]
              }
              {
                propertyValuesRelation: {
                  some: {
                    targetElement: {
                      AND: [
                        { iblock: { code: { equals: "sectionProductHero" } } }
                        {
                          propertyValues: {
                            some: { value: { contains: $searchString } }
                          }
                        }
                      ]
                    }
                  }
                }
              }
            ]
          }
        ]
      }
      first: $first
      after: $after
      orderBy: $orderBy
    ) {
      id
      name
      activeFrom
      active
      code
      iblock {
        code
      }
      seo {
        elementPageTitle
        elementMetaDescription
      }
      propertyValues {
        property {
          code
        }
        value
      }
      propertyValuesRelation(
        where: {
          OR: [
            { property: { type: FILE } }
            {
              targetElement: {
                iblock: { code: { equals: "sectionProductHero" } }
              }
            }
          ]
        }
      ) {
        property {
          code
          type
          kind
        }
        targetFile {
          url
          size
          contentType
        }
        targetElement {
          iblock {
            name
            id
            code
          }
          propertyValues(where: { property: { code: { equals: "text" } } }) {
            value
          }
        }
      }
    }
    elementsCount(
      where: {
        iblock: { code: { equals: $searchCategory } }
        propertyValues: { none: { value: { contains: "noindex, nofollow"} } }
        AND: [
          {
            propertyValuesEnum: {
              none: { property: { OR: [{ code: { equals: "hiddenPage" } }, { code: { equals: "uniquePages" } } ] } }
            }
          }
          {
            OR: [
              {
                AND: [
                  { name: { contains: $searchString } }
                  { iblock: { code: { equals: $searchCategory } } }
                ]
              }
              {
                AND: [
                  {
                    propertyValues: {
                      some: { value: { contains: $searchString } }
                    }
                  }
                  {
                    iblock: {
                      OR: [
                        { code: { equals: "document" } }
                        { code: { equals: "rgs_news" } }
                      ]
                    }
                  }
                ]
              }
              {
                propertyValuesRelation: {
                  some: {
                    targetElement: {
                      AND: [
                        { iblock: { code: { equals: "sectionProductHero" } } }
                        {
                          propertyValues: {
                            some: { value: { contains: $searchString } }
                          }
                        }
                      ]
                    }
                  }
                }
              }
            ]
          }
        ]
      }
    )
  }
`
