












import { Vue, Component, Prop } from 'nuxt-property-decorator'
import Intersect from 'vue-intersect'
import { defineComponent, ref } from '@nuxtjs/composition-api'

@Component
class BlockAnimation extends Vue {
  @Prop({ default: 0 }) readonly delay: Number
  static options: any
}

const BlockAnimationProps = BlockAnimation.options.props

export default defineComponent({
  name: 'BlockAnimation',
  components: {
    Intersect,
  },
  props: { ...BlockAnimationProps },
  setup() {
    const active = ref(false)

    const onEnter = () => {
      active.value = true
    }

    return {
      onEnter,
      active,
    }
  },
})
