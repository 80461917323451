

















import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { computed, defineComponent } from '@nuxtjs/composition-api'

@Component
class PopupFade extends Vue {
  @Prop() readonly isShown: boolean = false
  @Prop() readonly isNoPadding: boolean = false
  @Prop() readonly isFullSize: boolean = false
  @Prop() readonly fromBottomSide: boolean = false
  static options: any
}

export default defineComponent({
  name: 'PopupFade',
  props: PopupFade.options.props,
  setup(props, { emit, root }) {
    const onClose = (): void => {
      emit('hide-popup')
    }

    const isMobileMq = computed(
      (): boolean => root.$store.getters['mediaQuery/isMobileMq']
    )

    return {
      onClose,
      isMobileMq,
    }
  },
})
