// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/svg/icon/arrow_short_right.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*! mixins */.actions[data-v-e360c81a]{display:flex;width:100%;grid-gap:.8rem;gap:.8rem}.actions.left[data-v-e360c81a]{justify-content:flex-start}.actions.center[data-v-e360c81a]{justify-content:center}.actions.right[data-v-e360c81a]{justify-content:flex-end}@media (max-width:1023px){.actions .action-item[data-v-e360c81a]{flex:1}}.action-item[data-v-e360c81a]{position:static;display:flex;justify-content:center;align-items:center;border-radius:2px;border-radius:var(--btn-border-radius,2px)}.action-item__no-padding[data-v-e360c81a]{padding:0}.action-item__custom-styles[data-v-e360c81a]{background-color:var(--btn-background);color:var(--btn-text-color)}.action-item__custom-styles[data-v-e360c81a]:hover{background-color:var(--btn-background-hover);color:var(--btn-text-hover-color)}.action-item__border[data-v-e360c81a]{border:1px solid var(--btn-border-color)}.action-item.arrow-left[data-v-e360c81a]:after{content:\"\";width:1.6rem;height:1.6rem;background-color:currentColor;margin-left:.4rem;-webkit-mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center/100% no-repeat;mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center/100% no-repeat}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
