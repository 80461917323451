<template>
  <picture
    v-if="sources.length > 0"
    class="picture"
    :class="{ 'is-initial-size': isInitialSize }"
    :style="initialSizes"
  >
    <source
      v-for="(src, index) in sources"
      :key="index"
      :data-srcset="isLazy ? src.srcset : ''"
      :srcset="isLazy ? '' : src.srcset"
      :type="src.type"
      :media="src.media ? src.media : false"
    />
    <img
      :data-srcset="fallback.srcset"
      :data-src="fallback.src"
      :src="fallback.lqi"
      :alt="alt"
      :class="{
        lazyload: isLazy,
      }"
    />
  </picture>
</template>

<script>
import { mapState } from 'vuex'
import { Logger } from '@/composition/Logger'
const WEBP_CONTENT_TYPE = 'image/webp'
const PNG_CONTENT_TYPE = 'image/png'
const WEBP_URL_FORMAT = 'webp'
const PNG_URL_FORMAT = 'png'

export default {
  props: {
    id: {
      type: String,
      default: '_',
    },
    alt: {
      type: [String, Number],
      default: '',
    },
    sourceDesk: {
      type: String,
      default: '',
    },
    sourceMob: {
      type: String,
      default: '',
    },
    sourceDefault: {
      type: String,
      default: '',
    },
    isLazy: {
      type: Boolean,
      default: true,
    },
    sizes: {
      type: [Array, Object],
      default: () => [1600, 1600],
    },
    isInitialSize: {
      type: Boolean,
      default: true,
    },
    isCover: {
      type: Boolean,
      default: true,
    },
    quality: {
      type: Number,
      default: 80,
    },
    isLocal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sources: [],
      fallback: {},
      rootUrl: this.$config.IMAGE_OPTIMIZER_URL,
      webpContentType: WEBP_CONTENT_TYPE,
      pngContentType: PNG_CONTENT_TYPE,
      webpUrlFormat: WEBP_URL_FORMAT,
      pngUrlFormat: PNG_URL_FORMAT,
    }
  },
  computed: {
    ...mapState(['mediaQuery']),
    mqList() {
      return this.mediaQuery.list
    },
    mqLargest() {
      return this.mqList[this.mqList.length - 1].key
    },
    initialSizes() {
      const mqKey = this.mediaQuery.mqKey
      // если надо растянуть на весь родитель или нет пропы размеров для картинки
      if (!this.isInitialSize || !this.sizes) {
        return {}
      }
      // если размеры картинки не привязаны к mq и просто массив из ширины и высоты
      if (Array.isArray(this.sizes)) {
        return {
          width: `${this.sizes[0] / 10}rem`,
          height: `${this.sizes[1] / 10}rem`,
        }
      }
      // если они объект и в нем есть текущее mq
      else if (mqKey in this.sizes) {
        return {
          width: `${this.sizes[mqKey][0] / 10}rem`,
          height: `${this.sizes[mqKey][1] / 10}rem`,
        }
      }
      // если нет берем последнее (десктоп)
      else {
        return {
          width: `${this.sizes[this.mqLargest][0] / 10}rem`,
          height: `${this.sizes[this.mqLargest][1] / 10}rem`,
        }
      }
    },
  },
  watch: {
    sourceDesk() {
      this.init()
    },
    sourceMob() {
      this.init()
    },
    sourceDefault() {
      this.init()
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.sources = []
      this.fallback = {}
      // eslint-disable-next-line
      const regExp = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/g

      if (this.sourceDefault) {
        this.setSize(this.sourceDefault.replace(regExp, ''), true)
      } else {
        this.setSize(this.sourceDesk.replace(regExp, ''), false, true)
        this.setSize(this.sourceMob.replace(regExp, ''), true)
      }
    },
    generateUrl(sizes, isLqi, format = 'webp', source) {
      let result = source

      if (!this.isLocal) {
        const coverParam = this.isCover ? '&fit=cover' : ''
        const lqiParam = '&blur=10'

        result = `${this.rootUrl}${source}?format=${format}&w=${sizes[0]}&h=${
          sizes[1]
        }&q=${isLqi ? 50 : this.quality}${isLqi ? lqiParam : coverParam}`
      }

      return result
    },
    setSize(source, setFallback, isDesk) {
      if (this.sizes) {
        if (Array.isArray(this.sizes)) {
          const srcsetWebp = `${this.generateUrl(
            [this.sizes[0], this.sizes[1]],
            false,
            this.webpUrlFormat,
            source
          )}, ${this.generateUrl(
            [this.sizes[0] * 2, this.sizes[1] * 2],
            false,
            this.webpUrlFormat,
            source
          )} 2x`

          this.sources.push({
            srcset: srcsetWebp,
            type: this.webpContentType,
            media: false,
          })

          const srcsetPng = `${this.generateUrl(
            [this.sizes[0], this.sizes[1]],
            false,
            this.pngUrlFormat,
            source
          )}, ${this.generateUrl(
            [this.sizes[0] * 2, this.sizes[1] * 2],
            false,
            this.pngUrlFormat,
            source
          )} 2x`

          this.sources.push({
            srcset: srcsetPng,
            type: this.pngContentType,
            media: false,
          })

          return {
            srcset: `${srcsetWebp}, ${srcsetPng}`,
            src: this.generateUrl(
              [this.sizes[0], this.sizes[1]],
              false,
              this.webpUrlFormat,
              source
            ),
            lqi: this.generateUrl([25, 25], true, this.webpUrlFormat, source),
          }

          /* if (setFallback) {
            this.fallback = {
              srcset: `${srcsetWebp}, ${srcsetPng}`,
              src: this.generateUrl(
                [this.sizes[0], this.sizes[1]],
                false,
                this.webpUrlFormat,
                source
              ),
              lqi: this.generateUrl([25, 25], true, this.webpUrlFormat, source),
            }
          } */
        } else {
          for (const mq in this.sizes) {
            if (
              !!this.sourceDefault ||
              (isDesk && mq === 'lg') ||
              (!isDesk && mq !== 'lg')
            ) {
              const srcsetWebp = `${this.generateUrl(
                [this.sizes[mq][0], this.sizes[mq][1]],
                false,
                this.webpUrlFormat,
                source
              )}, ${this.generateUrl(
                [this.sizes[mq][0] * 2, this.sizes[mq][1] * 2],
                false,
                this.webpUrlFormat,
                source
              )} 2x`

              const srcsetPng = `${this.generateUrl(
                [this.sizes[mq][0], this.sizes[mq][1]],
                false,
                this.pngUrlFormat,
                source
              )}, ${this.generateUrl(
                [this.sizes[mq][0] * 2, this.sizes[mq][1] * 2],
                false,
                this.pngUrlFormat,
                source
              )} 2x`

              const mediaSize = this.mqList.find(
                (mqListItem) => mqListItem.key === mq
              ) || { value: 0 }

              this.sources.push({
                srcset: srcsetWebp,
                type: this.webpContentType,
                media: mediaSize.value
                  ? `(min-width: ${mediaSize.value}px)`
                  : '(max-width: 1024px)',
              })

              this.sources.push({
                srcset: srcsetPng,
                type: this.pngContentType,
                media: mediaSize.value
                  ? `(min-width: ${mediaSize.value}px)`
                  : '(max-width: 1024px)',
              })

              if (setFallback) {
                this.fallback = {
                  srcset: `${srcsetWebp}, ${srcsetPng}`,
                  src: this.generateUrl(
                    [
                      this.sizes[this.mqLargest][0],
                      this.sizes[this.mqLargest][1],
                    ],
                    false,
                    this.webpUrlFormat,
                    source
                  ),
                  lqi: this.generateUrl(
                    [25, 25],
                    true,
                    this.webpUrlFormat,
                    source
                  ),
                }
              }
            }
          }
        }
      } else {
        Logger.error('we need 404 image url here')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
picture,
img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.is-initial-size {
  display: block;
  position: relative;
  top: auto;
  left: auto;

  img {
    position: relative;
    top: auto;
    left: auto;
  }
}
</style>
