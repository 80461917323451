export default function (context) {
  const url = context?.req?.url
  if (
    url?.includes('/_nuxt/') ||
    url?.includes('/components/') ||
    url?.includes('/_loading/') ||
    url?.includes('/pages/') ||
    url?.includes('/layouts/') ||
    url?.includes('/__webpack_hmr/') ||
    !context?.req?.url
  ) {
    return
  }
  if (process.server) {
    context.customIP = context.req.headers['x-forwarded-for']
  }
}
