import { render, staticRenderFns } from "./SectionNavigation.vue?vue&type=template&id=59fa8575&scoped=true&"
import script from "./SectionNavigation.vue?vue&type=script&lang=ts&"
export * from "./SectionNavigation.vue?vue&type=script&lang=ts&"
import style0 from "./SectionNavigation.vue?vue&type=style&index=0&id=59fa8575&prod&scoped=true&lang=scss&"
import style1 from "./SectionNavigation.vue?vue&type=style&index=1&id=59fa8575&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59fa8575",
  null
  
)

export default component.exports