









































import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { defineComponent, computed } from '@nuxtjs/composition-api'
import PopupFromRight from '@/components/PopupFromRight'
import { PopupFaq } from '@/contracts/PopupFaq'
import { SimpleLink } from '@/contracts/SimpleLink'
import { CardIconImage as Card } from '@/contracts/CardIconImage'
import { BlockList as List } from '@/contracts/BlockList'
import BlockTagRow from '@/components/Tags/BlockTagRow'
import BlockList from '@/components/BlockList'
import CardIconImage from '@/components/Cards/CardIconImage'
import Link from '@/components/Link.vue'

@Component
class BlockPopupFaq extends Vue implements PopupFaq {
  @Prop() readonly blockList: List
  @Prop() readonly titleTags: string = ''
  @Prop() readonly titleList: string = ''
  @Prop() readonly tags: ReadonlyArray<any>
  @Prop() readonly card: Card
  @Prop() readonly tagsLink: SimpleLink
  @Prop() readonly listLink: SimpleLink
  static options: any
}
const BlockPopupFaqProps = BlockPopupFaq.options.props

export default defineComponent({
  name: 'BlockPopupFaq',
  components: { PopupFromRight, BlockList, BlockTagRow, CardIconImage, Link },
  props: BlockPopupFaqProps,
  setup(_props, { root }) {
    const onClose = (): void => {
      root.$store.commit('popupBuyOnline/SET_SHOW_FAQ_POPUP', false)
    }
    const isShown = computed(
      () => root.$store.getters['popupBuyOnline/getShowFaqPopup']
    )

    return {
      onClose,
      isShown,
    }
  },
})
