





























import { Vue, Component, Prop } from 'nuxt-property-decorator'
import {
  defineComponent,
  useStore,
  watch,
  computed,
  SetupContext,
} from '@nuxtjs/composition-api'
import { ThemeType } from '../../contracts/Settings'
import Link from '@/components/Link.vue'

@Component
class MobileMenu extends Vue {
  @Prop() readonly isActive: Boolean = false
  @Prop({
    default: () => ({
      activated: {
        link: '',
      },
      insuranceCase: {
        link: '',
      },
    }),
  })
  readonly actions: Object = {}

  static options: any
}

export default defineComponent({
  name: 'MobileMenu',
  components: { Link },
  props: MobileMenu.options.props,
  setup(props, { emit }: SetupContext) {
    const togglePopup = (): void => emit('toggle-popup')
    const store = useStore()
    const closeAllPopups = () => emit('close-all-popups')
    const breadcrumbs = computed<any[]>(
      () => store.state.breadcrumbs.breadcrumbs
    )

    watch(breadcrumbs, closeAllPopups, { deep: true })

    const { state, commit } = useStore()

    const onClickBurger = (): void => {
      emit('toggle-burger')
      commit('popupBuyOnline/SET_SHOW_BUTTON', props.isActive)
    }

    return {
      togglePopup,
      onClickBurger,
      closeAllPopups,
      isVipTheme: computed(() => state.settings.theme === ThemeType.vip),
    }
  },
})
