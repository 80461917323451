export const yaCounter = (params: string, gtm: string): void => {
  if (window && window.ym && gtm) {
    window.ym(396811, 'reachGoal', params)
  }
}

export const yaHitPage = () => {
  if (window && window.ym) {
    window.ym(396811, 'hit', window.location.href)
  }
}

export const yaReach = (param) => {
  if (window && window.ym) {
    window.ym(396811, 'reachGoal', param)
  }
}
