import { render, staticRenderFns } from "./Accordeon.vue?vue&type=template&id=9cc5c6ba&scoped=true&"
import script from "./Accordeon.vue?vue&type=script&lang=ts&"
export * from "./Accordeon.vue?vue&type=script&lang=ts&"
import style0 from "./Accordeon.vue?vue&type=style&index=0&id=9cc5c6ba&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9cc5c6ba",
  null
  
)

export default component.exports