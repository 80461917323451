































import { Vue, Component, Prop } from 'nuxt-property-decorator'
import Link from '@/components/Link.vue'

@Component({
  components: { Link },
})
export default class NavigationMenu extends Vue {
  @Prop({ default: '' }) readonly title: String | undefined
  @Prop({ default: () => [] }) readonly list: ReadonlyArray<any> | undefined
}
