// @ts-nocheck
import moment from 'moment'
import mime from 'mime-types'
import { propertyValuesNormalizer } from '../pageAdapter'
import { Element } from '../../composition-functions'
import { questionListItemNormalize, questionListNormalize } from './faqAdapter'
import { sectionDataAdapter } from "./sectionDataAdapter";
const DOC_TYPES = ['rar', 'zip']

export const sectionDocumentsListAdapter = (data: any) => {
  const { lastModified, title, list } = data
  let newList = []
  if (list.length === 1) {
    const [item] = list
    const _list = questionListNormalize(item)
    newList = _list.map(documentListItemNormalize)
  } else {
    newList = list.map((item: Element) => {
      const { propertyValues } = item
      const { title = 'Документы' } = propertyValuesNormalizer(propertyValues)
      const list = questionListNormalize(item)
      const { id } = item
      return {
        tabTitle: title,
        value: id,
        list: list.map(documentListItemNormalize),
      }
    })
  }
  return {
    withTabs: !!newList && !!newList[0] && !!newList[0].list,
    list: newList,
    lastModified,
    title,
    sectionData: sectionDataAdapter(data),
  }
}

export const documentListItemNormalize = (item: Element) => {
  const doc = questionListItemNormalize(item)
  const activeFrom = item.activeFrom && new Date(item.activeFrom)
  const { document: [{ url = '', size = 0, contentType = '' } = {}] = [{}] } =
    doc || {}
  const mappedSize = !size
    ? size
    : size > 1024 * 1024
    ? Math.round(size / (1024 * 1024)) + ' Мб'
    : Math.round(size / 1024) + ' Кб'
  if (doc.showDate && activeFrom) {
    doc.showDate = moment(activeFrom)
      .utcOffset(0, true)
      .format('DD.MM.YYYY HH:mm')
    if (activeFrom.getHours() === 5) {
      doc.showDate = doc.showDate.split(' ').slice(0, -1).join('')
    }
  } else if (doc.showDate) {
    doc.showDate = ''
  }
  if (doc.revisionDate) {
    doc.revisionDate = `Дата начала действия редакции: ${moment(
      doc.revisionDate
    ).format('DD.MM.YYYY')}`
  }
  return {
    url,
    pdfWeight: mappedSize,
    contentType: searchMime(contentType, url),
    ...doc,
  }
}

export const searchMime = (value: string, url: string): string => {
  const contentType = DOC_TYPES.find(
    (item) => url.split('.').reverse()[0].toLowerCase() === item
  )
  return (contentType || mime.extension(value) || 'file').toUpperCase()
}
