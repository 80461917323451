// @ts-nocheck
export default (data: any) => {
  return {
    sectionData: {
      sectionId: data.sectionId,
      sectionMainTitle: data.title || 'Адреса офисов продаж',
      sectionSideTitle:
        data.tag || 'Вы всегда можете приобрести полис в нашем офисе',
    },
  }
}
