

































import { defineComponent } from '@nuxtjs/composition-api'
import { defineProps } from '@/helpers/defineProps'
import { BlockCardListImage } from '@/contracts/blockCardList'
import CardImage from '@/components/Cards/CardImage.vue'

export default defineComponent({
  name: 'BlockCardImageGrid',
  components: { CardImage },
  props: defineProps<BlockCardListImage>({
    list: {
      default: () => [],
    },
  }),
  setup() {},
})
