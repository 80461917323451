// @ts-nocheck
import {
  propertyValuesRelationNormalizer,
  propertyValuesNormalizer,
} from '../pageAdapter'
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionLinksAdapter = (data: any) => {
  const {
    illustration,
    cardsLinkData: [{ propertyValuesRelation = [] } = {}] = [],
  } = data
  const { list = [] } = propertyValuesRelationNormalizer(propertyValuesRelation)
  const cardsLinkData = list.map(({ propertyValues = [] }) =>
    propertyValuesNormalizer(propertyValues)
  )
  const bol = illustration === 'Да'
  return {
    sectionData: sectionDataAdapter(data),
    illustration: bol,
    cardsLinkData,
  }
}
