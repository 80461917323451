






















import {
  computed,
  defineComponent,
  SetupContext,
  useStore,
} from '@nuxtjs/composition-api'
import { HeaderInfo } from '@/contracts/Settings'
import Cookie from '@/components/Cookie'
import SurveyButton from '@/components/SurveyButton.vue'
import SurveyPopup from '@/components/SurveyPopup.vue'
import ButtonBuyOnline from '@/components/ButtonBuyOnline'
import BlockPopupFaq from '@/components/BlockPopupFaq'
import PopupOldPage from '@/components/PopupOldPage'
import PopupReviewDetail from '@/components/PopupReviewDetail.vue'

export default defineComponent({
  name: 'LayoutBlocks',
  components: {
    PopupReviewDetail,
    Cookie,
    ButtonBuyOnline,
    BlockPopupFaq,
    PopupOldPage,
    SurveyButton,
    SurveyPopup,
  },
  setup(_props, ctx: SetupContext) {
    const { getters, commit } = useStore()

    const headerInfo = computed(
      () => ctx.root.$store.getters['settings/headerInfo'] as HeaderInfo
    )
    const showReviewDetailPopup = computed<boolean>(
      () => getters['popupReviewDetail/getShowPopupDetail']
    )
    const openReviewDetailPopup = computed<boolean>(
      () => getters['popupReviewDetail/getOpenPopupDetail']
    )
    const reviewDetail = computed(() => getters['popupReviewDetail/getReview'])
    const closePopup = (event: Event) => {
      const { target }: { target: EventTarget | null } = event
      if (target && target.closest('a')) {
        ctx.root.$store.commit('popupBuyOnline/TOGGLE_FAQ_POPUP')
      }
    }
    const closePopupReviewDetailHandle = () => {
      commit('popupReviewDetail/SET_OPEN_POPUP_DETAIL', false)
    }

    const surveyState = computed(() => getters['settings/survey'])
    const hideSurveyBtn = computed(() => getters['settings/getHideSurveyBtn'])

    return {
      headerInfo,
      closePopup,
      closePopupReviewDetailHandle,
      surveyState,
      hideSurveyBtn,
      reviewDetail,
      showReviewDetailPopup,
      openReviewDetailPopup,
    }
  },
})
