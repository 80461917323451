import { searchAdapter } from '../apollo/adapters/modules'
import { search } from '../apollo/queries/search'
export const SEARCH_LENGTH = 70

export const searchDataRequest = async (
  app: any,
  getters: any,
  searchQuery: string,
  components: []
) => {
  const { query } = app.apolloProvider.defaultClient
  const pagesList = getters['settings/pagesList']
  const fullResult = {}
  const searchString =
    searchQuery.length > SEARCH_LENGTH
      ? searchQuery.slice(0, SEARCH_LENGTH)
      : searchQuery

  const searchCategories = ['pageConstructor', 'rgs_news', 'document']

  for (const item of searchCategories) {
    const filters = {
      searchString,
      searchCategory: item,
      orderBy: {
        updatedAt: 'desc',
      },
      first: 5,
    }
    const {
      data: { elements, elementsCount },
    } = await query({
      query: search,
      variables: filters,
      fetchPolicy: 'no-cache',
    })

    const result = searchAdapter(elements, pagesList).map((item: any) => ({
      pdfWeight: item.size,
      contentType: item.contentType,
      title: item.title,
      text: item.text,
      url: item.url || '',
      id: item.id,
    }))

    fullResult[item] = {
      data: result,
      total: elementsCount,
    }
  }

  return {
    components: components.map((component) => {
      if (component.type === 'sectionSearch') {
        return {
          ...component,
          value: {
            ...component.value,
            result: fullResult,
            searchError: false,
          },
        }
      }
      return component
    }),
  }
}
