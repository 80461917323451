// @ts-nocheck
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionVideoAdapter = (data: any) => {
  const { urlVideo, name } = data

  return {
    sectionData: sectionDataAdapter(data),
    urlVideo,
    name,
  }
}
