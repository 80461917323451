



















import { defineComponent, PropType, useRoute } from '@nuxtjs/composition-api'
import { ButtonAction } from '@/contracts/ButtonHeroAction'
import { useNuxtLink } from '@/composition/nuxtLink'
import { buttonsAction } from '@/composition/buttons'

export default defineComponent({
  name: 'BlockButtons',
  props: {
    buttonActions: {
      type: Array as PropType<ButtonAction[]>,
      default: () => [],
    },
    alignment: {
      type: String as PropType<'left' | 'center' | 'right'>,
      default: 'left',
    },
  },
  setup(_, { emit }) {
    const route = useRoute()
    const { clickHandler } = buttonsAction()

    const getStyles = (button: ButtonAction) => {
      if (button.color) return null
      return {
        '--btn-background': button.background,
        '--btn-background-hover': button.backgroundHover,
        '--btn-border-color': button.borderColor,
        '--btn-text-color': button.labelColor,
        '--btn-text-hover-color': button.labelHoverColor,
        '--btn-border-radius': button.borderRadius,
      }
    }

    const getClasses = (button: ButtonAction) => {
      return {
        'arrow-left': button.arrow,
        'action-item__no-padding': !button.padding && !button.color,
        'action-item__custom-styles':
          button.labelHoverColor ||
          button.labelColor ||
          button.background ||
          button.backgroundHover,
        'action-item__border': button.borderColor,
        'btn--basic':
          (button.color === 'Красная' && button.type !== 'switchLink') ||
          (button.type === 'switchLink' && route.value.fullPath === button.url),
        'btn--second':
          (button.color === 'Белая' && button.type !== 'switchLink') ||
          (button.type === 'switchLink' && route.value.fullPath !== button.url),
      }
    }
    const componentProps = (item) => {
      const { isNuxtLink } = useNuxtLink(item.url)

      if (!item.url) {
        return { type: 'button' }
      }

      if (!isNuxtLink.value) {
        return {
          is: 'a',
          href: item.url,
        }
      }

      if (item.type === 'link' || item.type === 'switchLink')
        return { to: item.url }
      return { type: 'button' }
    }
    const onClick = (button: ButtonAction) => {
      clickHandler(
        button.type,
        button.eventAction,
        button.eventCategory,
        button.jsCode
      )
      emit('button-click', button)
    }

    return {
      componentProps,
      getStyles,
      onClick,
      getClasses,
    }
  },
})
