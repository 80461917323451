












import { Vue, Component, Prop } from 'nuxt-property-decorator'
import {
  defineComponent,
  onMounted,
  computed,
  SetupContext,
  nextTick,
  useRouter,
} from '@nuxtjs/composition-api'
import { debounce } from 'throttle-debounce'
import { yaReach } from '@/composition/ym'
@Component
class BlockText extends Vue {
  @Prop() readonly textData: String = ''
  @Prop() readonly fullWidth: Boolean = false
  @Prop() readonly showFullTable: 'Да' | 'Нет' | '' = ''
  @Prop() readonly alignment: 'left' | 'right' = 'left'
  static options: any
}

export default defineComponent({
  props: BlockText.options.props,
  setup(props, ctx: SetupContext) {
    const openText = computed(
      () => ctx.root.$store.getters['settings/btnShowMore']
    )
    const closeText = computed(
      () => ctx.root.$store.getters['settings/btnRollUp']
    )
    const router = useRouter()
    const isNuxtLink = /^(http|ftp|tel:|mailto:)/

    onMounted(() => {
      nextTick(() => {
        const el = ctx.refs.blockText
        el.querySelectorAll('a').forEach((link) => {
          const href = link.getAttribute('href')
          if (!isNuxtLink.test(href)) {
            link.onclick = (e) => {
              if (link.dataset.metrika) yaReach(link.dataset.metrika)
              e.preventDefault()
              router.push(href)
            }
          } else {
            link.onclick = () => {
              if (link.dataset.metrika) yaReach(link.dataset.metrika)
            }
          }
        })

        el.querySelectorAll('.table-wrapper').forEach((wrapper) => {
          const table = wrapper.querySelector('table')
          const fullTableAttribute = table.getAttribute('full-table')
          const countRows = table.querySelectorAll('tr')
          if (
            props.showFullTable !== 'Да' &&
            !fullTableAttribute &&
            countRows.length > 5
          ) {
            const resizeHandler = (isResize: boolean = false) => {
              const clientHeight: number = table.scrollHeight
              let previewHeight: number = 0
              let index: number = 0
              while (index < 5) {
                previewHeight += countRows[index].scrollHeight
                index++
              }
              if (!Array.from(wrapper.classList).includes('opened')) {
                wrapper.style.cssText = `height: ${previewHeight}px;`
              } else {
                wrapper.style.cssText = `height: ${clientHeight}px;`
              }
              const onClickBtnHandler = (btnEl): void => {
                const opened: boolean = Array.from(wrapper.classList).includes(
                  'opened'
                )
                if (opened) {
                  wrapper.style.cssText = `height: ${previewHeight}px;`
                  btnEl.classList.remove('opened')
                  wrapper.classList.remove('opened')
                  btnEl.innerHTML = openText.value
                } else {
                  wrapper.style.cssText = `height: ${clientHeight}px;`
                  wrapper.classList.add('opened')
                  btnEl.classList.add('opened')
                  btnEl.innerHTML = closeText.value
                }
              }

              if (!isResize) {
                const button = document.createElement('button')
                button.innerHTML = openText.value
                button.classList.add('btn--show-more')
                button.onclick = (): void => onClickBtnHandler(button)
                wrapper.after(button)
              } else {
                const button = wrapper.nextSibling
                button.onclick = (): void => onClickBtnHandler(button)
              }
            }

            resizeHandler()
            const debouncedResizeHandler = debounce(50, false, () =>
              nextTick(() => resizeHandler(true))
            )
            window.addEventListener('resize', debouncedResizeHandler)
          }
        })
      })
    })
  },
  template: '',
})
